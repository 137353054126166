<template>
	<div>
		<div class="mt-30">
			<!-- 커버등록 -->
			<div
				class="radius-20 justify-center items-center"
				style="height: 300px"
				:style="'background-image: url(' + upload_file + '); background-repeat: repeat; background-position: center;'"
			>
				<label
					class="box radius-100 pa-10"
				>
					<img
						:src="require('@/assets/image/web/notify_cartel/ico_set_photo.svg')"
					/>
					<input type="file" id="albumFile" class="hide" accept="image/*" @change="setFile" ref="file">
				</label>
			</div>

			<div class="mt-30">
				<div>
					<label for="cartelName" class="h5">카르텔 이름</label>
					<div
						class="box radius-20 pa-10-20 flex-row items-center mt-20"
					>
						<input
							v-model="item_cartel.cartl_name"
							type="text" id="cartelName" placeholder="카르텔 이름을 입력하세요." maxlength="20"
							class="flex-1"
						>
						<v-icon
							v-if="item_cartel.cartl_name"
							@click="item_cartel.cartl_name = ''"
							class="mr-10 size-px-18"
						>mdi-close-circle</v-icon>
						<span style="flex-basis: 40px" class="text-right">{{ item_cartel.cartl_name.length }}/20</span>
					</div>
				</div>
				<div class="mt-20">
					<label for="cartelDesc" class="h5">카르텔설명</label>
					<div
						class="box radius-20 pa-10-20 flex-row items-center mt-20"
					>
						<input
							v-model="item_cartel.cartl_desctn"
							type="text" id="cartelDesc" placeholder="카르텔에 대한 설명을 해주세요." maxlength="40"
							class="flex-1"
						>
						<v-icon
							v-if="item_cartel.cartl_desctn"
							@click="item_cartel.cartl_desctn = ''"
							class="mr-10 size-px-18"
						>mdi-close-circle</v-icon>
						<!-- TODO : 텍스트 수 카운트 필요 -->
						<span style="flex-basis: 40px" class="text-right">{{ item_cartel.cartl_desctn.length }}/40</span>
					</div>
				</div>

				<hr class="under-line mt-30">

				<div class="mt-30">
					<template
						v-if="is_approval"
					>
						<div class="flex-row justify-space-between items-center">
							<h5>가입 승인</h5>
							<p class="toggle-group h_unset" @click="entry_approval_fg = !entry_approval_fg">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<span class="btn-toggle " :class="{ on: !entry_approval_fg}"><em class="hide">가입 승인</em></span>
							</p>
						</div>
						<div class="color-gray">
							보스, 또는 언더보스의 승인을 통해서만 카르텔 가입이 가능합니다.
						</div>
					</template>
					<template
						v-else
					>
						<div class="flex-row justify-space-between items-center">
							<h5>가입 승인 여부</h5>
							<p class="toggle-group h_unset">
								<span class="btn-toggle " ><em class="hide">가입 승인</em></span>
							</p>
						</div>
						<div class="color-gray">
							{{  entry_approval_chg_confirmation_message }}
						</div>
					</template>
				</div>

				<hr class="under-line mt-30">

				<div class="mt-30">
					<h5>카르텔 관심사를 선택하세요.</h5>
					<ul class="mt-20">
						<li
							v-for="(int, i_index) in items_interest"
							:key="'int_' + i_index"
							class="ptb-10"
						>
							<div class="bright_radio interest_area">

								<input
									v-model="concern_sphere_code"
									:value="int.code"
									type="radio"
									:id="int.code"
								>
								<label
									:for="int.code"
								>
									<strong class="inline-block width-10">{{ int.label }}</strong>
									<p class="inline-block">{{  int.text }}</p>
								</label>
							</div>
						</li>
					</ul>
				</div>

				<div class="top-line mt-30 ptb-30 text-right">
					<button
						class="btn-inline btn-primary radius-20"
						@click="postCartel"
					>저장</button>
				</div>


			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'mafia0741'
	, props: ['callBack', 'user']
	, data: function(){
		return {
			program: {
				name: '카르텔 개설'
				, title: ''
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, is_next: true
				, callBack: {
					name: 'cartel'
					, step: 1
				}
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {
				step: 1
				, cartl_name: ''
				, cartl_desctn: ''
				, entry_approval_fg: false
				, concern_sphere_code: []
				, user_id: this.user.id
			}
			, cartel_img: ''
			, concern_sphere_code: []
			, step: 1
			, upload_file: ''
			, entry_approval_fg: false
			, entry_approval_chg_fg: false
			, entry_approval_chg_confirmation_message: ''
			, items_interest: [
				{ name: 'music', code: 'CM00400001', label: 'Music', text: '음악을 좋아하고 관심을 가지고 있습니다.'}
				, { name: 'art', code: 'CM00400002', label: 'Art', text: '예술, 디장인등에 관심을 가지고 있습니다.'}
				, { name: 'fashion', code: 'CM00400003', label: 'Fashion', text: '패션 디자인, 모델등에 관심을 가지고 있습니다.'}
				, { name: 'influence', code: 'CM00400004', label: 'Influence', text: '유튜버, 블로거 등을 운영하는것에 관심을 가지고 있습니다.'}
			]
		}
	}
	, computed: {
		is_approval: function(){
			let t = true

			if(this.entry_approval_chg_confirmation_message.indexOf('가입대기중') > -1){
				t = false
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.upload_file = result.data.cartl_img_url
					this.concern_sphere_code = result.data.cartl_concern_sphere_code
					this.entry_approval_fg = result.data.entry_approval_fg == 'Y' ? true : false

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

					this.$storage.setQuery(this.item_search)
					await this.getCartelApproval()

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelApproval: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_approval
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, entry_approval_fg: 'N'
					}
					, type: true
				})

				if(result.success){
					this.entry_approval_chg_fg = result.data.entry_approval_chg_fg
					this.entry_approval_chg_confirmation_message = result.data.entry_approval_chg_confirmation_message
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setFile: function(e){

			this.cartel_img = this.$refs.file.files[0]
			console.log(e)

			const reader = new FileReader()
			let self = this
			reader.onload = function(e){
				self.upload_file = e.target.result
			}
			reader.readAsDataURL(e.target.files[0])
		}
		, postFile: async function(){
			try{
				this.$bus.$emit('on', true)
				if(this.cartel_img) {
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_file
						, data: {
							member_number: this.user.member_number
							, file_upload_code: 'CM00700002'
							, appendix_file_div_code: 'CA01000001'
							, appendix_file_name: this.cartel_img
						}
						, multipart: true
						, type: true
					})

					if (result.success) {
						this.upload_file = result.data.file_url
						return true
					} else {
						throw result.message
					}
				}else{
					return true
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCartel: async  function(){
			console.log('postCartel', this.item_cartel)

			if(!this.upload_file){
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 이미지를 선택하세요'})
				return false
			}else if (!this.item_cartel.cartl_name || this.item_cartel.cartl_name == '') {
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 이름을 입력하세요'})
				return false
			} else if (!this.item_cartel.cartl_desctn || this.item_cartel.cartl_desctn == '') {
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 설명을 입력하세요'})
				return false
			}

			if (!this.concern_sphere_code) {
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 관심사를 선택하세요'})
				return false
			}

			if(await this.postFile()){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_name: this.item_cartel.cartl_name
							, cartl_desctn: this.item_cartel.cartl_desctn
							, entry_approval_fg: this.entry_approval_fg ? 'Y' : 'N'
							, concern_sphere_code: this.concern_sphere_code
							, cartl_img_url: this.upload_file
						}
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'mafia074', params: {idx: this.$route.params.idx}})
					}else{
						throw result.message
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		,goBack: function(){
			if(this.step == 1){
				this.$emit('goBack')
			}else{
				this.step = 1
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getCartel()
	}
}
</script>